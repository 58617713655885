import CryptoJS from "crypto-js";

const EncryptionService = (text, secretPass) => {
    return {
        encryptData: () => {
            const data = CryptoJS.AES.encrypt(
                JSON.stringify(text),
                secretPass
            ).toString();

            return data;
        },
        decryptData: () => {
            const bytes = CryptoJS.AES.decrypt(text, secretPass);
            const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return data;
        }
    }
}

export default EncryptionService;