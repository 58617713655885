function getQueryString(params) {
  return Object.keys(params)
    .map(k => k + '=' + params[k])
    // .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
}
/**
 * Function: request
 * Description: Make a request to the server and return a promise.
 */
function request(url, options) {
  if (!url) {
    throw new Error('Preflight request error: URL parameter required');
  }

  if (!options) {
    throw new Error('Preflight request error: options parameter required');
  }

  var method = options.method || 'GET';
  var qs = '';
  var body;

  var headers = options.headers || {
    'Accept': 'application/json',
    'content-type': 'application/json',
  };

  var mode = options.mode || 'cors'; // no-cors, *cors, same-origin
  var cache = options.cachecontrol || 'no-cache'; // *default, no-cache, reload, force-cache, only-if-cached
  var credentials = options.credentials || 'same-origin'; // include, *same-origin, omit

  // var mode = options.mode || 'cors';

  if (['GET', 'DELETE'].indexOf(method) > -1 && options.param && JSON.stringify(options.param) != "{}" && JSON.stringify(options.param) != "[]") {

    qs = '?' + getQueryString(options.param);
  }
  else {
    if (options.param && Object.keys(options.param).length > 0)
      qs = '?' + getQueryString(options.param);

    if (url.indexOf("connect/token") > -1) {
      body = options.data;
    } else {
      if (typeof options.data == 'string' && (url && url.toLowerCase().indexOf('api/application/updatemenu') == -1)) {
        body = options.data;
      } else {
        body = JSON.stringify(options.data);
      }
    }
  }
  var url1 = url + qs;
  if (url1 && url1.includes("NoApi")) {
    return Promise.resolve({});
  }
  // Fetch returns a promise
  return fetch(url1, { method, mode, cache, credentials, headers, body })
    .then(response => {

      if (response.status > 200 && response.status != 202 && response.status != 204) {
        // if (response.status == 401) {
        //   localStorage.removeItem('state')
        // }
        // Errors such as this are passed up to the middleware
        // throw new Error(`Server error: ${response.status} status`);
        return Promise.resolve({ status: 'error', message: `Server error: ${response.status} status`, statusCode: response.status })
      }

      return response.json();
    })
    .then(response => {
      if (!response) {
        return response;
      } else if (response.errors) {
        throw new Error(`Server error: ${response.errors.message}`);
      }

      return response;
    });
}

export default request;
