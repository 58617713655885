import { combineReducers } from "redux";
import formDataReducer from "../../forms/reducer";
import formLoginReducer from "../../screens/Login/reducer";
import { MessagesReducer, ConversationsReducer, ThreadReducer, ThreadMembersReducer, ContosoReducer } from "../../forms/Comm-Chat/reducers";
import { callsReducer, devicesReducer, streamsReducer, controlsReducer, sdkReducer } from "../../forms/Comm-VideoCall/reducers";

var {
  LOGIN
} = require('../../config/constants');

const appReducer = combineReducers({
  calls: callsReducer,
  devices: devicesReducer,
  streams: streamsReducer,
  controls: controlsReducer,
  sdk: sdkReducer,
  chat: MessagesReducer,
  contosoClient: ContosoReducer,
  conversations: ConversationsReducer,
  thread: ThreadReducer,
  threadMembers: ThreadMembersReducer,
  formDataReducer,
  formLoginReducer
});
const rootReducer = (state, action) => {
  if (action.type === LOGIN.LOGIN_LOGOUT) {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem('state')
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }

  return appReducer(state, action)
}
export default rootReducer;
