export const SET_MIC = 'SET_MIC';
export const SET_SHARE_SCREEN = 'SET_SHARE_SCREEN';
export const RESET_CONTROLS = 'RESET_CONTROLS';

export const setMic = (mic) => {
  return {
    type: SET_MIC,
    mic
  };
};

export const setShareScreen = (shareScreen) => {
  return {
    type: SET_SHARE_SCREEN,
    shareScreen
  };
};

export const resetControls = () => {
  return {
    type: RESET_CONTROLS
  };
};