export const SET_SMS = 'SET_SMS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_TYPING_NOTIFICATIONS = 'SET_TYPING_NOTIFICATIONS';
export const SET_TYPING_USERS = 'SET_TYPING_USERS';
export const SET_FAILED_MESSAGES = 'SET_FAILED_MESSAGES';

export const setSMS = (messages) => ({
    type: SET_SMS,
    messages
});
export const setMessages = (messages) => ({
    type: SET_MESSAGES,
    messages
});
export const setTypingNotifications = (id, typingNotification) => ({
    type: SET_TYPING_NOTIFICATIONS,
    id,
    typingNotification
});
export const setTypingUsers = (typingUsers) => ({
    type: SET_TYPING_USERS,
    typingUsers
});
export const setFailedMessages = (failedMessages) => ({
    type: SET_FAILED_MESSAGES,
    failedMessages
});