import {
  CALL_ADDED,
  CALL_REMOVED,
  SET_CALL_STATE,
  SET_GROUP,
  SET_PARTICIPANTS,
  SET_CALL_AGENT,
  CALL_RETRIED
} from '../actions/calls';


const initialState = {
  callAgent: undefined,
  call: undefined,
  callState: 'None',
  incomingCallEndReason: undefined,
  groupCallEndReason: undefined,
  remoteParticipants: [],
  group: '',
  attempts: 0
};

export const callsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALL_AGENT:
      return { ...state, callAgent: action.callAgent };
    case CALL_ADDED:
      return { ...state, call: action.call };
    case CALL_REMOVED:
      return {
        ...state,
        call: undefined,
        remoteParticipants: [],
        incomingCallEndReason: action.incomingCallEndReason,
        groupCallEndReason: action.groupCallEndReason
      };
    case SET_CALL_STATE:
      return { ...state, callState: action.callState };
    case SET_PARTICIPANTS:
      return { ...state, remoteParticipants: action.remoteParticipants };
    case SET_GROUP:
      return { ...state, group: action.group };
    case CALL_RETRIED:
      return { ...state, attempts:action.attempts} 
    default:
      return state;
  }
};
