
export const SET_LOCAL_PREVIEW_RENDERER = 'SET_LOCAL_PREVIEW_RENDERER';
export const SET_LOCAL_VIDEO_STREAM = 'SET_LOCAL_STREAM';
export const ADD_STREAM = 'ADD_STREAM';
export const REMOVE_STREAM = 'REMOVE_STREAM';
export const ADD_SCREENSHARE_STREAM = 'ADD_SCREENSHARE_STREAM';
export const REMOVE_SCREENSHARE_STREAM = 'REMOVE_SCREENSHARE_STREAM';
export const RESET_STREAMS = 'RESET_STREAMS';

export const setLocalVideoStream = (localVideoStream) => {
  return {
    type: SET_LOCAL_VIDEO_STREAM,
    localVideoStream
  };
};

export const addScreenShareStream = (
  stream,
  user
) => {
  return {
    type: ADD_SCREENSHARE_STREAM,
    stream,
    user
  };
};

export const removeScreenShareStream = (
  stream,
  user
) => {
  return {
    type: REMOVE_SCREENSHARE_STREAM,
    stream,
    user
  };
};

export const resetStreams = () => {
  return {
    type: RESET_STREAMS
  };
};
