import { SET_THREAD_MEMBERS, SET_THREAD_MEMBERS_ERROR, SET_REMOVE_THREAD_MEMBER_ERROR, SET_ADD_THREAD_MEMBER_ERROR } from '../actions/ThreadMembersAction';
const initState = {
    threadMembers: [],
    error: false,
    removeThreadMemberError: false,
    addThreadMemberError: undefined
};
export const ThreadMembersReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_THREAD_MEMBERS:
            return Object.assign(Object.assign({}, state), { threadMembers: [...action.threadMembers] });
        case SET_THREAD_MEMBERS_ERROR:
            return Object.assign(Object.assign({}, state), { error: true });
        case SET_REMOVE_THREAD_MEMBER_ERROR:
            return Object.assign(Object.assign({}, state), { removeThreadMemberError: action.removeThreadMemberError });
        case SET_ADD_THREAD_MEMBER_ERROR:
            return Object.assign(Object.assign({}, state), { addThreadMemberError: action.addThreadMemberError });
        default:
            return state;
    }
};