export const SET_CALL_AGENT = 'SET_CALL_AGENT';
export const SET_GROUP = 'SET_GROUP';
export const CALL_ADDED = 'CALL_ADDED';
export const CALL_REMOVED = 'CALL_REMOVED';
export const CALL_RETRIED = 'CALL_RETRIED';
export const SET_CALL_STATE = 'SET_CALL_STATE';
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';


export const setCallAgent = (callAgent) => {
  return {
    type: SET_CALL_AGENT,
    callAgent
  };
};

export const setGroup = (groupId) => {
  return {
    type: SET_GROUP,
    group: groupId
  };
};

export const callAdded = (addedCall) => {
  return {
    type: CALL_ADDED,
    call: addedCall
  };
};

export const callRemoved = (removedCall, group) => {
  return {
    type: CALL_REMOVED,
    call: undefined,
    incomingCallEndReason: removedCall.isIncoming ? removedCall.callEndReason : undefined,
    groupCallEndReason: !removedCall.isIncoming && !!group ? removedCall.callEndReason : undefined
  };
};

export const setCallState = (callState) => {
  return {
    type: SET_CALL_STATE,
    callState
  };
};

export const setParticipants = (participants) => {
  return {
    type: SET_PARTICIPANTS,
    remoteParticipants: participants
  };
};

export const callRetried = (attempts) => {
  return {
    type: CALL_RETRIED,
    attempts: attempts
  }
}

