import { SET_MIC, SET_SHARE_SCREEN, RESET_CONTROLS } from '../actions/controls';

const initialState = {
  mic: false,
  localVideo: false,
  shareScreen: false
};

export const controlsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_MIC:
      return { ...state, mic: action.mic };
    case SET_SHARE_SCREEN:
      return { ...state, shareScreen: action.shareScreen };
    case RESET_CONTROLS:
      return initialState;
    default:
      return state;
  }
};
