import {
  DATA_HAS_ERRORED,
  DATA_IS_LOADING,
  DATA_FETCH_SUCCESS,
  FETCHING_DATA,
  UPDATING_DATA,
  UPDATING_FILTERDATA,
  UPDATING_EDITFORMDATA,
  DATA_RESET_SUCCESS,
  FILTERDATA_RESET_SUCCESS,
  EDITDATA_RESET_SUCCESS,
  PARENTDATA_RESET_SUCCESS,
  UPDATING_PARENTCTRLDATA,
  RESET_SIDEBAR,
  SET_SIDEBAR_FORMID,
  SET_SIDEBAR_FORMNAME,
  SET_MODEL_FORMID,
  SET_MODEL_FORMNAME,
  SET_PAGE_PARAMS,
  SET_UPLOAD_PARAMS,
  RESET_RELOAD,
  DELELTE_KEY,
  BOOKMARK_SET,
  RESET_MODELPOPUP,
  REALTIME_SYNC,
  RESET_FORM,
  NOTIFICATION_IMPORT,
  SET_GUID,
  SET_SPLITVIEW_FORMID,
  SET_DIRTY_FORM_MODEL
} from '../config/constants';
var _ = require("lodash");

const initialState = {
  isLoading: false,
  hasErrored: false,
  filterdata: [],
  editformdata: {},
  parentctrdata: {},
  isSidebarOpened: false,
  sidebarFormId: null,
  sidebarFormName: null,
  modelFormId: null,
  modelFormName: null,
  pageparams: {},
  uploadparams: null,
  reload: false,
  bookmark: [],
  isModelOpened: false,
  realtimeSync: false,
  notification_import: [],
  GUID: null,
  splitviewFormId: null,
  dirty_form_model: {}
};
export default function (state = initialState, action) {
  switch (action.type) {
    case DATA_HAS_ERRORED:
      return { ...state, hasErrored: action.hasErrored };
    case DATA_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case DATA_FETCH_SUCCESS:
      return { ...state, [action.storekey]: action[action.storekey] };
    case FETCHING_DATA:
      return { ...state, [action.storekey]: action[action.storekey] };
    case DATA_RESET_SUCCESS:
      return { ...state, [action.storekey]: action[action.storekey] };
    case FILTERDATA_RESET_SUCCESS:
      return { ...state, filterdata: action.filterdata };
    case EDITDATA_RESET_SUCCESS:
      return { ...state, editformdata: action.editformdata };
    case PARENTDATA_RESET_SUCCESS:
      return { ...state, parentctrdata: action.parentctrdata };
    case UPDATING_DATA:

      return {
        ...state,
        [action.storekey]: {
          ...state[action.storekey],
          [action.key]: action.value
        }
      }
    case UPDATING_EDITFORMDATA:

      return {
        ...state,
        editformdata: {
          ...state.editformdata,
          [action.key]: action.value
        }
      }
    case UPDATING_FILTERDATA:

      let model = state.filterdata.find(x => x.id == action.key);
      if (model) {
        if (model.type !== 'range' && model.type !== 'daterange' && model.type !== 'number')
          model.value = action.value;
        else {
          model.value[action.key2] = action.value;
        }
      }
      return {
        ...state,
        filterdata: [
          ...state.filterdata
        ]
      }
    case UPDATING_PARENTCTRLDATA:

      return {
        ...state,
        parentctrdata: {
          ...state.parentctrdata,
          [action.key]: action.value
        }
      }
    case SET_DIRTY_FORM_MODEL:

      return {
        ...state,
        dirty_form_model: {
          ...state.dirty_form_model,
          [action.key]: action.value
        }
      }
    case RESET_SIDEBAR:
      return { ...state, isSidebarOpened: action.isSidebarOpened };
    case RESET_MODELPOPUP:
      return { ...state, isModelOpened: action.isModelOpened };
    case REALTIME_SYNC:
      return { ...state, realtimeSync: action.realtimeSync };
    case SET_SIDEBAR_FORMID:
      return { ...state, sidebarFormId: action.sidebarFormId };
    case SET_SIDEBAR_FORMNAME:
      return { ...state, sidebarFormName: action.sidebarFormName };
    case SET_MODEL_FORMID:
      return { ...state, modelFormId: action.modelFormId };
    case SET_MODEL_FORMNAME:
      return { ...state, modelFormName: action.modelFormName };
    case SET_PAGE_PARAMS:
      return { ...state, pageparams: action.pageparams };
    case SET_UPLOAD_PARAMS:
      return { ...state, uploadparams: action.uploadparams };
    case RESET_RELOAD:
      return { ...state, reload: action.reload };
    case DELELTE_KEY:

      var _state = _.omit(state, action.storekey);
      return { ...state, _state }
    case BOOKMARK_SET:
      return { ...state, bookmark: action.bookmark };
    case NOTIFICATION_IMPORT:
      return { ...state, notification_import: action.notification_import };
    case RESET_FORM:
      var _state = state;
      for (let key in state) {
        if (key.indexOf('write') > -1 || key.indexOf('filter') > -1) {
          delete _state[key];
        }
      }

      return _state;
    case SET_GUID:
      return { ...state, GUID: action.GUID };
    case SET_SPLITVIEW_FORMID:
      return { ...state, splitviewFormId: action.splitviewFormId };
    default:
      return state;
  }
}
