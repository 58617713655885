import Network from "../http/http/network";

var stream = [];
const azureFunction = resource => {
    var { url, model, Token, headers, connection, method, control } = resource;

    return {
        call: () => {
            if (method == "post") {
                return Network().post(url, model, Token, null, headers, connection);
            }
            else if (method == "put") {
                return Network().put(url, model, Token, headers, connection);
            }
            else if (method == "delete") {
                return Network().delete(url, null, Token, headers, connection);
            } else if (method == "patch") {
                return Network().put(url, model, Token, headers, connection);
            }
            else {
                if (control.enablecache) {
                    if (!stream[url]) {
                        stream[url] = Network().get(url, null, Token, headers, connection);
                    }
                    return stream[url];
                } else {
                    return Network().get(url, null, Token, headers, connection);
                }
            }
        }
    }
}

export default azureFunction;