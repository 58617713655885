export const SET_THREAD_MEMBERS = 'SET_THREAD_MEMBERS';
export const SET_THREAD_MEMBERS_ERROR = 'SET_THREAD_MEMBERS_ERROR';
export const SET_REMOVE_THREAD_MEMBER_ERROR = 'SET_REMOVE_THREAD_MEMBER_ERROR';
export const SET_ADD_THREAD_MEMBER_ERROR = 'SET_ADD_THREAD_MEMBER_ERROR';
export const setThreadMembers = (threadMembers) => ({
    type: SET_THREAD_MEMBERS,
    threadMembers
});
export const setThreadMembersError = (error) => ({
    type: SET_THREAD_MEMBERS_ERROR,
    error
});
export const setRemoveThreadMemberError = (removeThreadMemberError) => ({
    type: SET_REMOVE_THREAD_MEMBER_ERROR,
    removeThreadMemberError
});
export const setAddThreadMemberError = (addThreadMemberError) => ({
    type: SET_ADD_THREAD_MEMBER_ERROR,
    addThreadMemberError
});