export const APPLICATION_INITIALIZE_CHECK = 'APPLICATION_INITIALIZE_CHECK';
export const APPLICATION_SERVER_CHECK = 'APPLICATION_SERVER_CHECK';

/**
 * Varibles defined for Dynamic Forms Actions - to store DataModal in redux Store
 */
export const DATA_HAS_ERRORED = 'DATA_HAS_ERRORED'
export const DATA_IS_LOADING = 'DATA_IS_LOADING'
export const FETCHING_DATA = 'FETCHING_DATA'
export const UPDATING_DATA = 'UPDATING_DATA'
export const UPDATING_FILTERDATA = 'UPDATING_FILTERDATA'
export const UPDATING_EDITFORMDATA = 'UPDATING_EDITFORMDATA'
export const DATA_RESET_SUCCESS = 'DATA_RESET_SUCCESS'
export const FILTERDATA_RESET_SUCCESS = 'FILTERDATA_RESET_SUCCESS'
export const EDITDATA_RESET_SUCCESS = 'EDITDATA_RESET_SUCCESS'
export const PARENTDATA_RESET_SUCCESS = 'PARENTDATA_RESET_SUCCESS'
export const UPDATING_PARENTCTRLDATA = 'UPDATING_PARENTCTRLDATA'
export const BOOKMARK_SET = 'SET_BOOKMARK'
export const NOTIFICATION_IMPORT = 'NOTIFICATION_IMPORT'
export const RESET_FORM = 'RESET_FORM'
/**
 * Varibles defined for Forms Actions for Redux 
 */

export const ITEMS_HAS_ERRORED = 'ITEMS_HAS_ERRORED'
export const ITEMS_IS_LOADING = 'ITEMS_IS_LOADING'
export const DATA_FETCH_SUCCESS = 'DATA_FETCH_SUCCESS'
export const ITEMS_FETCH_DATA_SUCCESS = 'ITEMS_FETCH_DATA_SUCCESS'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_DISCARD_TOKEN = 'AUTH_DISCARD_TOKEN'
export const RESET_SIDEBAR = 'RESET_SIDEBAR'
export const RESET_MODELPOPUP = 'RESET_MODELPOPUP'
export const REALTIME_SYNC = 'REALTIME_SYNC'
export const SET_SIDEBAR_FORMID = 'SET_SIDEBAR_FORMID'
export const SET_SIDEBAR_FORMNAME = 'SET_SIDEBAR_FORMNAME'
export const SET_MODEL_FORMID = 'SET_MODEL_FORMID'
export const SET_MODEL_FORMNAME = 'SET_MODEL_FORMNAME'
export const SET_PAGE_PARAMS = 'SET_PAGE_PARAMS'
export const SET_UPLOAD_PARAMS = 'SET_UPLOAD_PARAMS'
export const RESET_RELOAD = 'RESET_RELOAD'
export const DELELTE_KEY = 'DELELTE_KEY'
export const EMAIL_SERVICE_SENDGRID = 'SENDGRID'
export const SET_GUID = 'SET_GUID'
export const SET_SPLITVIEW_FORMID = 'SET_SPLITVIEW_FORMID'
export const SET_DIRTY_FORM_MODEL = 'SET_DIRTY_FORM_MODEL'
export const LOGIN = {
    CHANGE_LOGGED_IN: 'CHANGE_LOGGED_IN',
    AUTH_SET_TOKEN: 'AUTH_SET_TOKEN',
    AUTH_DISCARD_TOKEN: 'AUTH_DISCARD_TOKEN',
    AUTH_SET_USER: 'AUTH_SET_USER',
    SET_MENU: 'AUTH_SET_MENU',
    SET_DATASOURCE: 'SET_DATASOURCE',
    LOGIN_ATTEMPT: 'LOGIN_ATTEMPT',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGIN_LOGOUT: 'LOGIN_LOGOUT',
    LOGIN_SUCCESSFULLY: 'LOGIN_SUCCESSFULLY',
    LOGIN_RESET_CONTROL_VARS: 'LOGIN_RESET_CONTROL_VARS',
    LOGIN_RESET_NAV_BAR: 'LOGIN_RESET_NAV_BAR'
}


// Chatting configurations
export const COOL_PERIOD_THRESHOLD = 60 * 1000;
export const INITIAL_MESSAGES_SIZE = 2000;
export const MAXIMUM_LENGTH_OF_NAME = 10;
export const MAXIMUM_LENGTH_OF_MESSAGE = 8000;
export const MAXIMUM_LENGTH_OF_TOPIC = 30;
export const MAXIMUM_LENGTH_OF_TYPING_USERS = 35;
export const MAXIMUM_RETRY_COUNT = 3;
export const MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS = 8000;
export const MAXIMUM_INT64 = 9223372036854775807;
export const NUMBER_OF_MESSAGES_TO_LOAD = 10;
export const PAGE_SIZE = 200;
export const PARTICIPANTS_THRESHOLD = 20;

// Keyboard keys
export const ENTER_KEY = 13;
export const SPACE_KEY = 32;

// Http Status Code
export const OK = 200;
export const CREATED = 201;
export const MULTI_STATUS = 207;
export const PRECONDITION_FAILED_STATUS_CODE = 412;
export const TOO_MANY_REQUESTS_STATUS_CODE = 429;

// Regex
export const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const EMPTY_MESSAGE_REGEX = /^\s*$/;
