export const SET_USERID = 'SET_USERID';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';

export const setUserId = (userId) => {
  return {
    type: SET_USERID,
    userId
  };
};

export const setDisplayName = (displayName) => {
  return {
    type: SET_DISPLAY_NAME,
    displayName
  };
};

