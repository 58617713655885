export const SET_DEVICE_MANAGER = 'SET_DEVICE_MANAGER';
export const SET_AUDIO_DEVICE_INFO = 'SET_AUDIO_DEVICE_INFO';
export const SET_VIDEO_DEVICE_INFO = 'SET_VIDEO_DEVICE_INFO';
export const SET_AUDIO_DEVICE_LIST = 'SET_AUDIO_DEVICE_LIST';
export const SET_VIDEO_DEVICE_LIST = 'SET_VIDEO_DEVICE_LIST';
export const SET_MICROPHONE_PERMISSION = 'SET_MICROPHONE_PERMISSION';
export const SET_CAMERA_PERMISSION = 'SET_CAMERA_PERMISSION';

export const setDeviceManager = (deviceManager) => {
  return {
    type: SET_DEVICE_MANAGER,
    deviceManager
  };
};

export const setMicrophonePermission = (microphonePermission) => {
  return {
    type: SET_MICROPHONE_PERMISSION,
    microphonePermission
  };
};

export const setCameraPermission = (cameraPermission) => {
  return {
    type: SET_CAMERA_PERMISSION,
    cameraPermission
  };
};

export const setAudioDeviceInfo = (audioDeviceInfo) => {
  return {
    type: SET_AUDIO_DEVICE_INFO,
    audioDeviceInfo
  };
};

export const setVideoDeviceInfo = (videoDeviceInfo ) => {
  return {
    type: SET_VIDEO_DEVICE_INFO,
    videoDeviceInfo
  };
};

export const setAudioDeviceList = (audioDeviceList) => {
  return {
    type: SET_AUDIO_DEVICE_LIST,
    audioDeviceList
  };
};

export const setVideoDeviceList = (videoDeviceList) => {
  return {
    type: SET_VIDEO_DEVICE_LIST,
    videoDeviceList
  };
};
