import { settings } from "../../../config/settings";
import ControlService from "../../../helper/controlservice";
import Network from "../http/http/network";

const storageService = resource => {
    var { model,
        modelname,
        connection,
        Token,
        method,
        params,
        datasource } = resource;
    model = typeof model == 'string' ? JSON.parse(model) : model;
    var api = '';
    if (datasource == settings.DATASOURCE.GITHUB) {
        api = '/github';
    } else if (datasource == settings.DATASOURCE.DEVOPS) {
        api = '/azuredevops';
    }
    let encrypt = (model) => {
        if (settings.APP_ENCRYPTION_ENABLE === "true") {
            model = ControlService().encryptData(model);
        }
        return model;
    }
    let decrypt = (model) => {
        if (settings.APP_ENCRYPTION_ENABLE === "true") {
            model = ControlService().decryptData(model);
        }
        return model;
    }
    let getAFUrl = () => {
        if (modelname == settings.WORKBENCH.EMAILCONNECTIONS) {
            if (method == 'delete' || method == 'get' || method == 'create' || method == 'put' || method == 'post') {
                params["id"] = model.id;
                return '/api' + api + '/emailsource';
            } else {
                params["id"] = modelname;
                return '/api' + api + '/emailsource/list';
            }
        } else if (modelname == settings.WORKBENCH.DATACONNECTIONS) {
            if (method == 'delete' || method == 'get' || method == 'create' || method == 'put' || method == 'post') {
                params["id"] = model.id;
                return '/api' + api + '/datasource';
            } else {
                params["id"] = modelname;
                return '/api' + api + '/datasource/list';
            }
        } else if (modelname == settings.WORKBENCH.APPLICATION && (method == 'delete' || method == 'get' || method == 'create' || method == 'put' || method == 'post')) {
            return '/api' + api + '/microapp';
        } else if (modelname == settings.WORKBENCH.APPLICATION) {
            return '/api' + api + '/microapp/list';
        } else if (modelname == settings.WORKBENCH.MENU) {
            params["id"] = modelname;
            return '/api' + api + '/menu';
        } else if (modelname == settings.WORKBENCH.GROUP && (method == 'delete' || method == 'get' || method == 'create' || method == 'put' || method == 'post')) {
            return '/api' + api + '/group';
        } else if (modelname == settings.WORKBENCH.GROUP) {
            return '/api' + api + '/group/list';
        } else if (modelname == settings.WORKBENCH.FORMLIST && (method == 'delete' || method == 'create' || method == 'put' || method == 'post')) {
            return '/api' + api + '/forms';
        } else if (modelname == settings.WORKBENCH.FORMLIST) {
            return '/api' + api + '/forms/list';
        } else {
            params["id"] = modelname;
            return '/api' + api + '/forms';
        }
    }
    let readAF = (partitionKey, query, headers) => {
        var url = (Token.AzureFunctionEndpoint ? Token.AzureFunctionEndpoint : settings.REACT_APP_AZURE_FUNCTION_ENDPOINT) + "/api/entity/SearchAsync";
        var options = {};
        options["cid"] = connection.id;
        options["model"] = partitionKey;
        if (datasource == settings.DATASOURCE.GITHUB) {
            options["branch"] = Token && Token.selectedgitbranch ? Token.selectedgitbranch : '';
        }
        var payload = {};
        if (params) {
            payload = {
                "query": query,
                "parameters": params
            };
        } else {
            payload = {
                "query": query
            };
        }
        return Network().post(url, payload, headers, options, [], {});
    }

    let getAF = (headers) => {
        var url = (Token.AzureFunctionEndpoint ? Token.AzureFunctionEndpoint : settings.REACT_APP_AZURE_FUNCTION_ENDPOINT) + getAFUrl();

        let options = params;
        if (datasource == settings.DATASOURCE.GITHUB) {
            if (!options) {
                options = {};
            }
            options["branch"] = Token && Token.selectedgitbranch ? Token.selectedgitbranch : '';
        }

        return Network().get(url, options, Token, headers, connection).then(res => {
            return decrypt(res.data ? res.data : res);
        }).catch(err => {
            return { "status": "error", "statusCode": 401, "message": err };
        });
    };
    let createAF = (partitionKey, headers) => {
        // model = typeof model == 'string' ? JSON.parse(model) : model;
        var options = {};
        if (datasource == settings.DATASOURCE.GITHUB) {
            options["branch"] = Token && Token.selectedgitbranch ? Token.selectedgitbranch : '';
        }
        var url = (Token.AzureFunctionEndpoint ? Token.AzureFunctionEndpoint : settings.REACT_APP_AZURE_FUNCTION_ENDPOINT) + getAFUrl();
        return Network().post(url, encrypt(model), Token, options, headers, connection);
    };

    let updateAF = (partitionKey, headers) => {
        // model = typeof model == 'string' ? JSON.parse(model) : model;
        var options = {};
        options["id"] = model.id ? model.id : model.Id;
        if (datasource == settings.DATASOURCE.GITHUB) {
            options["branch"] = Token && Token.selectedgitbranch ? Token.selectedgitbranch : '';
        }
        var url = (Token.AzureFunctionEndpoint ? Token.AzureFunctionEndpoint : settings.REACT_APP_AZURE_FUNCTION_ENDPOINT) + getAFUrl();
        return Network().put(url, encrypt(model), options, Token, headers, connection);
    };
    let deleteAF = (partitionKey, headers) => {
        let options = params;
        options["id"] = model.id;
        var url = (Token.AzureFunctionEndpoint ? Token.AzureFunctionEndpoint : settings.REACT_APP_AZURE_FUNCTION_ENDPOINT) + getAFUrl();
        if (datasource == settings.DATASOURCE.GITHUB) {
            options["branch"] = Token && Token.selectedgitbranch ? Token.selectedgitbranch : '';
        }
        return Network().delete(url, options, Token, headers);

    }
    return {
        call_AzBlob: async () => {
            //connection.enableazurefunction = true;
            // connection.enablecors = true;
            var headers = [];
            headers.push({
                key: 'Content-Type', value: 'application/json'

            });
            if (settings.REACT_APP_ENABLE_PERSONA == 'yes') {
                headers.push({
                    key: 'X-ZUMO-AUTH', value: Token.XZUMOAUTH
                });
                headers.push({
                    key: 'org', value: Token.user.companyid
                });
            } else {
                if (settings.LOGIN_SERVICE != 'azureb2c') {
                    headers.push({
                        key: 'x-functions-key', value: settings.REACT_APP_AZURE_FUNCTION_KEY
                    });
                }
            }
            const accesskey = Token.accesstoken;
            // if (settings.REACT_APP_AZURE_FUNCTION_KEY) {
            //     headers.push({
            //         key: 'Authorization', value: 'Basic ' + settings.REACT_APP_AZURE_FUNCTION_KEY
            //     });
            // }
            // else {
            headers.push({
                key: 'Authorization', value: 'Bearer ' + accesskey
            });
            // }

            const idTokens = Token.apikey;
            if (idTokens) {
                headers.push({
                    key: 'api-key', value: idTokens
                });
            }

            if (accesskey) {
                headers.push({
                    key: 'accesskey', value: accesskey
                });
            }
            if ((method == "update" || method == "put") && !model.Id && !model.id) {
                method = "create";
            }
            switch (method) {
                case 'create':
                    return createAF(modelname, headers);
                case 'update':
                    return updateAF(modelname, headers);
                case 'put':
                    return updateAF(modelname, headers);
                case 'delete':
                    return deleteAF(modelname, headers);
                case settings.METHOD.GET:
                    return getAF(headers);
                case settings.METHOD.LIST:
                    return getAF(headers);
                case settings.METHOD.POST:
                    return createAF(modelname, headers);
                // case 'sprocs':
                //     return sprocs( modelname, params, sprocname);
                default:
                    return readAF(modelname, cosmosquery, headers);
            }

        }
    }
}

export default storageService;

