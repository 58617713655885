import * as Msal from '@azure/msal-browser';
import { azureProvider } from './configure'

export default class AzureAuthService {

    constructor() {

        this.app = new Msal.PublicClientApplication(azureProvider.config);
    }

    init = async (loginIsLoading) => {
        var response = null;
        try {

            let tokenResponse = await this.app.handleRedirectPromise();

            let accountObj;
            if (tokenResponse) {
                accountObj = tokenResponse.account;
            } else {
                accountObj = this.app.getAllAccounts()[0];
            }
            if (accountObj && tokenResponse) {
                response = tokenResponse;
                // console.log("[AuthService.init] Got valid accountObj and tokenResponse")
            } else if (accountObj) {
                try {

                    tokenResponse = await this.app.acquireTokenSilent({
                        account: this.app.getAllAccounts()[0],
                        scopes: azureProvider.authenticationParameters
                    });
                    response = tokenResponse;
                } catch (err) {
                    loginIsLoading();
                    await this.app.acquireTokenRedirect(azureProvider.authenticationParameters);
                }
            } else {
                console.log("[AuthService.init] No accountObject or tokenResponse present. User must now login.");
                loginIsLoading();
                await this.app.loginRedirect(azureProvider.authenticationParameters)
            }
        } catch (error) {
            console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
            try {
                loginIsLoading();
                await this.app.loginRedirect(azureProvider.authenticationParameters)
            } catch {

            }
        }

        return response;
    }
    signIn = async () => {
        console.log('[AuthService.signIn] signInType:', this.signInType);
        this.app.loginRedirect(azureProvider.authenticationParameters);
    }
    passwordReset = async () => {
        // console.log('[AuthService.signIn] signInType:', this.signInType);
        this.app.loginRedirect(azureProvider.configForgotPassword);
    }
    signOut = (url) => {
        debugger;
        const logoutRequest = {
            postLogoutRedirectUri: window.location.origin + url
        };
        return this.app.logoutRedirect(logoutRequest);
    }
}