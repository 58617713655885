import { SET_MESSAGES, SET_TYPING_NOTIFICATIONS, SET_TYPING_USERS, SET_FAILED_MESSAGES, SET_SMS } from '../actions/MessagesAction';
const initMessagesState = {
    sms: [],
    messages: [],
    typingNotifications: {},
    typingUsers: [],
    failedMessages: []
};
export const MessagesReducer = (state = initMessagesState, action) => {
    switch (action.type) {
        case SET_SMS:
            return Object.assign(Object.assign({}, state), { sms: [...action.messages] });
        case SET_MESSAGES:
            return Object.assign(Object.assign({}, state), { messages: [...action.messages] });
        case SET_TYPING_NOTIFICATIONS:
            return Object.assign(Object.assign({}, state), { typingNotifications: Object.assign(Object.assign({}, state.typingNotifications), { [action.id]: action.typingNotification }) });
        case SET_TYPING_USERS:
            return Object.assign(Object.assign({}, state), { typingUsers: [...action.typingUsers] });
        case SET_FAILED_MESSAGES:
            return Object.assign(Object.assign({}, state), { failedMessages: [...action.failedMessages] });
        default:
            return state;
    }
};