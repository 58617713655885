import request from './request';
import { settings } from "../../../../config/settings";

/* eslint react/forbid-prop-types: 0 */
var regx = /^((https?:[/][/])?(\w+[.])+com|((https?:[/][/])?(\w+[.])+com[/]|[.][/])?\w+([/]\w+)*([/]|[.]html|[.]php|[.]gif|[.]jpg|[.]png)?)$/;
/**
 * @function Network
 * @description Factory function to create a object that can send
 * requests to a specific resource on the server.
 * @param {string} resource The resource used for config
 */
const Network = resource => {
  let buildURL = (path, Token, connection) => {
    var res = path.match(regx);
    if (path.indexOf("http") > -1) {
      return path;
    }
    else if (res != null) {
      return [
        connection && connection.baseurl ? connection.baseurl : Token && Token.baseapiurl ? Token.baseapiurl : settings.BASE_API_URL,
        path
      ].join('/');
    }
    else {
      return path;
    }

  }
  const addHeaders = (headers, Token, isCorsEnable) => {
    var httpOptions = {
      headers: {}
    };
    if (isCorsEnable) {
      // httpOptions.mode = "no-cors";
      httpOptions.headers['Access-Control-Allow-Origin'] = '*';
      // httpOptions.headers['Access-Control-Allow-Methods'] = '*';
      // httpOptions.headers['Access-Control-Allow-Headers'] = '*';
    }
    if (headers && headers.length > 0) {
      headers.forEach(hd => {
        httpOptions.headers[hd['key']] = hd['value'];
      });
    } else {
      httpOptions = {
        mode: "cors",
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Access-Control-Allow-Origin': window.location.host,
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Connection': 'keep-alive',
          'content-type': 'application/json; charset=UTF-8',
          'Strict-Transport-Security': 'max-age=65540 ; includeSubDomains',
          'X-XSS-Protection': 1,
          // Pragma: 'no-cache',
          'Expires': 0,
          'api-key': Token.apikey
        }
      };

      const idTokens = Token.apikey;
      if (idTokens) {

        httpOptions.headers['api-key'] = Token.apikey;

      }
    }
    return httpOptions;
  }
  // I perform a GET request to the external API, appending the given params
  // as URL search parameters. Returns a stream.
  let getExt = (url, params, Token, Headers, connection) => {
    params.url = url;
    var apiparam = {};
    apiparam.param = JSON.stringify(params);
    return request(buildURL('api/service/get', Token, connection), Object.assign(
      {
        param: apiparam
      },
      defaultOptions(Token)
      ,
      { method: 'GET' }
    ));
  }

  // I perform a external POST request to the API. If both the params and data
  // are present, the params will be appended as URL search parameters
  // and the data will be serialized as a JSON payload. If only the
  // data is present, it will be serialized as a JSON payload. Returns
  // a stream.
  let postExt = (url, data, params, Token, Headers, connection) => {
    params.url = url;
    var apiparam = {};
    apiparam.param = JSON.stringify(params);
    return request(buildURL('api/service/post', Token, connection), Object.assign(
      {
        param: apiparam,
        data: data
      },
      defaultOptions(Token)
      ,
      {
        method: 'POST'
      }
    ));
  }
  // Default options used for every request
  let defaultOptions = (Token = null) => {
    if (Token) {

      return {
        mode: "cors",
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Access-Control-Allow-Origin': window.location.host,
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Connection': 'keep-alive',
          'content-type': 'application/json; charset=UTF-8',
          'X-XSS-Protection': 1,
          'Strict-Transport-Security': 'max-age=65540 ; includeSubDomains',
          // Pragma: 'no-cache',
          'Authorization': 'Bearer ' + Token.accesstoken,
          'org': Token.user?.companyid,
          //'X-ZUMO-AUTH': Token?.XZUMOAUTH
          'Expires': 0,
          'api-key': Token.apikey
        }
      }
    } else {
      return {
        mode: "cors",
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Access-Control-Allow-Origin': window.location.host,
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Connection': 'keep-alive',
          'content-type': 'application/json; charset=UTF-8',
          'X-XSS-Protection': 1,
          'Strict-Transport-Security': 'max-age=65540 ; includeSubDomains',
          // Pragma: 'no-cache',
          'Expires': 0,
          'api-key': Token.apikey
        }
      }
    }
  };
  return {
    /**
     * @function post
     * @description Make a POST request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    post: (path, body, Token, options, Headers, connection) => {
      options = setParams(options, Token, path);
      path = path.indexOf("/") > 0 ? path : path.substring(1);
      // var res = path.match(regx);
      if (Array.isArray(Headers) && Headers.length > 0) {
        // if (res != null) {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options,
            data: body
          },
          addHeaders(Headers, Token, connection.enablecors)
          ,
          {
            method: 'POST'
          }
        ));
        // } else {
        //   return postExt(path, body, options, Token, Headers, connection);
        // }
      } else {
        // if (res != null || !connection.enablecors) {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options,
            data: body
          },
          defaultOptions(Token)
          ,
          {
            method: 'POST'
          }
        ));
        // } else {
        //   return postExt(path, body, options, Token, Headers, connection);
        // }
      }
    },

    /**
     * @function get
     * @description Make a GET request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    get: (path, options, Token, Headers, connection) => {
      options = setParams(options, Token, path);
      path = path && path.indexOf("/") > 0 ? path.trim() : path.substring(1).trim();
      // var res = path.match(regx);
      if (Array.isArray(Headers) && Headers.length > 0) {
        // if (res != null) {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options
          },
          addHeaders(Headers, Token, connection.enablecors)
          ,
          { method: 'GET' }
        ));
        // }
        // else {
        //   return getExt(path, options, Token, Headers, connection);
        // }
      }
      else {
        // if (res != null || !connection.enablecors) {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options
          },
          defaultOptions(Token)
          ,
          { method: 'GET' }
        ));
        // }
        // else {
        //   return getExt(path, options, Token);
        // }
      }
    },

    /**
     * @function put
     * @description Make a PUT request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    put: (path, body, options, Token, Headers, connection) => {
      options = setParams(options, Token, path);
      if (Array.isArray(Headers) && Headers.length > 0) {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options,
            data: body
          },
          addHeaders(Headers, Token)
          ,
          {
            method: 'PUT'
          }
        ));
      } else {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options,
            data: body
          },

          defaultOptions(Token),
          { method: 'PUT' }
        ));
      }
    },

    /**
     * @function delete
     * @description Make a DELETE request.
     * @param {string} path
     * @p
     * aram {object} options
     * @returns {promise}
     */
    delete: (path, options, Token, Headers, connection) => {
      options = setParams(options, Token, path);
      path = path && path.indexOf("/") > 0 ? path : path.substring(1);
      if (Array.isArray(Headers) && Headers.length > 0) {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options
          },
          addHeaders(Headers, Token)
          ,
          { method: 'DELETE' }
        ));
      }
      else {
        return request(buildURL(path, Token, connection), Object.assign(
          {
            param: options
          },
          defaultOptions(Token)
          ,
          {
            method: 'DELETE'
          }
        ));
      }
    },
    /**
     * @function login
     * @description Make a POST request for authentication.
     * @param {string} path
     * @param {object} body
     * @param {string} username
     * @param {string} password
     * @returns {promise}
     */
    login: (username, password) => {
      var body = [];
      let details = {
        "username": username,
        "password": password,
        'grant_type': 'password',
        "scope": 'offline_access openid email phone profile roles',
        "resource": settings.BASE_API_URL
      }
      var header = {
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 0,
          'X-XSS-Protection': 1,
          'Strict-Transport-Security': 'max-age=65540 ; includeSubDomains'
        }
      }

      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        body.push(encodedKey + "=" + encodedValue);
      }
      body = body.join("&");
      return request(settings.BASE_API_URL + "connect/token", Object.assign(
        {
          data: body
        },
        header,
        {
          method: 'POST'
        }
      ));
    },
    getRefreshLoginEndpoint: (group, Token) => {
      var body = [];
      let details = {
        "username": 'changegroup',
        "password": group,
        'grant_type': 'password',
        "scope": 'offline_access openid email phone profile roles',
        "resource": window.location.origin
      }
      var header = {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'Authorization': "Bearer " + Token.accesstoken,
          'X-MS-TOKEN-AAD-ACCESS-TOKEN': Token.accesstoken
        }
      }

      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        body.push(encodedKey + "=" + encodedValue);
      }
      body = body.join("&");
      return request(settings.BASE_API_URL + "connect/token", Object.assign(
        {
          data: body
        },
        header,
        {
          method: 'POST'
        }
      ));
    }
  };
};

export default Network;

function setParams(options, Token, url) {
  if (!options) {
    options = {};
  }
  if (Token) {
    let wbBaseUrl = Token.AzureFunctionEndpoint ? Token.AzureFunctionEndpoint : settings.REACT_APP_AZURE_FUNCTION_ENDPOINT;
    let isWbRequest = url.includes(wbBaseUrl);
    if ((url.includes(settings.REACT_APP_COSMOS_DB_ENDPOINT) || isWbRequest || settings.LOGIN_SERVICE === 'ncameo') && Token.user && Token.user.ActiveApp && !options['idx']) {
      options['idx'] = Token.user.ActiveApp.database;
    }
    if (!isWbRequest && Token.menu && Token.menu.applicationclaimSelected && Token.menu.applicationclaims) {
      options[Token.menu.claimapibindingfield] = Token.menu.applicationclaimSelected;
    }
  }
  return options;
}
