import { SET_USERID, SET_DISPLAY_NAME } from '../actions/sdk';

const initialState = {
  displayName: ''
};
export const sdkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERID:
      return { ...state, userId: action.userId };
    case SET_DISPLAY_NAME:
      return { ...state, displayName: action.displayName }
    default:
      return state;
  }
};
