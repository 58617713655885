import { SET_THREAD, SET_THREAD_TOPIC_NAME, SET_THREAD_ID } from '../actions/ThreadAction';
const initThreadState = {
    thread: {},
    threadId: undefined
};
export const ThreadReducer = (state = initThreadState, action) => {
    switch (action.type) {
        case SET_THREAD:
            return Object.assign(Object.assign({}, state), { thread: action.thread });
        case SET_THREAD_TOPIC_NAME:
            return Object.assign(Object.assign({}, state), { thread: Object.assign(Object.assign({}, state.thread), { topic: action.topicName }) });
        case SET_THREAD_ID:
            return Object.assign(Object.assign({}, state), { threadId: action.threadId });
        default:
            return state;
    }
};