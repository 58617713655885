import Network from "./http/network";
import { settings } from '../../../config/settings'

var stream = [];
const httpService = resource => {
    var { url, params, model, Token, headers, connection, method, datasource, control } = resource;

    let setPathParams = () => {
        var pathParams = [];
        Object.keys(params).forEach(_param => {
            if (url.indexOf("{{" + _param + "}}") > -1) {
                pathParams.push(_param);
                url = url.replace("{{" + _param + "}}", params[_param])
            }
        });
        pathParams.forEach(_pathParam => {
            delete params[_pathParam];
        })
        return { params, url };
    }
    let generateHeader = () => {
        if (datasource == settings.DATASOURCE.HTTP && connection && Object.keys(connection).length > 0) {
            headers = headers ? headers : [];
            url = url && url.includes("/") > 0 ? url.trim() : url.substring(1).trim();

            url = connection.baseurl + "/" + url;
            if (connection.authentication == "loginauth" || connection.authentication == "oauth10" || connection.authentication == "basic" || connection.authentication == "token") {
                headers.push({
                    key: 'Content-Type', value: 'application/json'
                });
                switch (connection.authentication) {
                    case "token":
                        headers.push({
                            key: 'Authorization', value: connection.accesskey
                        });
                        break;
                    case "basic":
                        headers.push({
                            key: 'Accept', value: 'application/json'
                        });
                        headers.push({
                            key: 'Accept-Language', value: 'en-US'
                        });
                        headers.push({
                            key: 'Authorization', value: 'Basic ' + btoa(connection.username + ":" + connection.password)
                        });
                        break;
                    case "loginauth":
                        const authTokens = Token.accesstoken;
                        if (authTokens) {
                            headers.push({
                                key: 'Authorization', value: 'Bearer ' + authTokens
                            });
                        }
                        const idTokens = Token.apikey;
                        if (idTokens) {
                            headers.push({
                                key: 'api-key', value: idTokens
                            });
                        }
                        if (settings.REACT_APP_ENABLE_PERSONA == "yes") {
                            headers.push({
                                key: 'org', value: Token.user.companyid
                            });
                            headers.push({
                                key: 'X-ZUMO-AUTH', value: Token.XZUMOAUTH
                            });
                        }
                        break;
                    default:
                    // code block
                }
            }
        }
        return { headers, url };
    }
    return {
        call: () => {
            ({ headers, url } = generateHeader());

            if (params) {
                ({ params, url } = setPathParams());
            }

            if (method == "post") {
                return Network().post(url, model, Token, params, headers, connection);
            }
            else if (method == "put") {
                return Network().put(url, model, params, Token, headers, connection);
            }
            else if (method == "delete") {
                return Network().delete(url, params, Token, headers, connection);
            } else if (method == "patch") {
                return Network().put(url, model, Token, headers);
            } else if (method == "poco") {
                return Promise.resolve(model);
            }
            else {
                if (control.enablecache) {
                    var QueryStr = Object.keys(params ? params : [])
                        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                        .join('&');
                    if (!stream[QueryStr]) {
                        stream[QueryStr] = Network().get(url, params, Token, headers, connection);
                    }
                    return stream[QueryStr];
                } else {
                    return Network().get(url, params, Token, headers, connection);
                }
            }
        }
    }
}

export default httpService;