import { SET_CHAT_CLIENT, SET_CURRENT_USER, SET_USERS, SET_CURRENT_USER_COOL_PERIOD } from '../actions/ContosoClientAction';
const initContosoState = {
    chatClient: undefined,
    user: { identity: '', token: '', displayName: '', memberRole: '' },
    users: new Map()
};
export const ContosoReducer = (state = initContosoState, action) => {
    switch (action.type) {
        case SET_CHAT_CLIENT:
            return Object.assign(Object.assign({}, state), { chatClient: action.chatClient });
        case SET_CURRENT_USER:
            return Object.assign(Object.assign({}, state), { user: {
                    identity: action.identity,
                    token: action.token,
                    displayName: action.displayName,
                    memberRole: action.memberRole
                } });
        case SET_CURRENT_USER_COOL_PERIOD:
            return Object.assign(Object.assign({}, state), { user: {
                    identity: state.user.identity,
                    token: state.user.token,
                    displayName: state.user.displayName,
                    memberRole: state.user.memberRole,
                    coolPeriod: action.coolPeriod
                } });
        case SET_USERS:
            return Object.assign(Object.assign({}, state), { users: [...action.users] });
        default:
            return state;
    }
};