var {
    LOGIN
} = require('../../config/constants');
export const _INITIAL_STATE_ = {
    token: {
        accesstoken: null,
        apikey: null,
        user: {},
        dataconnection: [],
        UserApps: [],
        menu: { Nav: [] }
    },
    menu: [],
    isLoading: false,
    loggedIn: false,
    lastError: undefined,
    hasError: false,
    resetNavigation: undefined,
    isNavBarOpen: false
};
export default function (state = _INITIAL_STATE_, action) {
    switch (action.type) {
        // saves the token into the state
        case LOGIN.AUTH_SET_TOKEN:
            return {
                ...state,
                token: action.token
            };
        // discards the current token (logout)
        case LOGIN.AUTH_DISCARD_TOKEN:
            return {};
        // saves the current user
        // case LOGIN.AUTH_SET_USER:
        //     return {
        //         ...state,
        //         user
        //     };
        // saves the current user
        case LOGIN.SET_MENU:

            return {
                ...state,
                menu: action.menu
            }
        case LOGIN.LOGIN_SUCCESSFULLY:
            return {
                ...state,
                loggedIn: true
            };

        case LOGIN.LOGIN_FAILED:
            return {
                ...state,
                lastError: action.lastError,
                hasError: action.hasError,
                loggedIn: false
            };


        case LOGIN.LOGIN_ATTEMPT:
            return {
                ...state,
                isLoading: action.isLoading,
                loggedIn: false
            };

        case LOGIN.LOGIN_RESET_CONTROL_VARS:
            return {
                ...state,
                hasError: false,
                resetNavigation: undefined,
            };
        case LOGIN.LOGIN_RESET_NAV_BAR:
            return {
                ...state,
                isNavBarOpen: action.isNavBarOpen
            };
        case LOGIN.LOGIN_LOGOUT:
            return {
                ...state,
                hasError: false,
                loggedIn: false,
                resetNavigation: action.resetNavigation
            };
        // as always, on default do nothing
        default:
            return state;
    }
}
