import { settings } from '../../../config/settings'

const authority = () => {
    return settings.LOGIN_SERVICE == 'azureb2c' ? 'https://' + settings.REACT_APP_B2C_BASE_URL + '/tfp/' + settings.REACT_APP_TENANT_URL + '/' + settings.REACT_APP_B2CSIGNUP_URL : 'https://login.microsoftonline.com/' + settings.REACT_APP_TENANT_URL
}
const scopes = () => {
    return settings.LOGIN_SERVICE == 'azureb2c' ?
        ['https://' + settings.REACT_APP_TENANT_URL + '/' + settings.REACT_APP_CLIENT_ID + '/user.read', 'https://' + settings.REACT_APP_TENANT_URL + '/' + settings.REACT_APP_CLIENT_ID + '/user_impersonation', 'https://' + settings.REACT_APP_TENANT_URL + '/' + settings.REACT_APP_CLIENT_ID + '/profile', 'https://' + settings.REACT_APP_TENANT_URL + '/' + settings.REACT_APP_CLIENT_ID + '/offline_access'] :
        ['https://graph.microsoft.com/User.Read', 'https://graph.microsoft.com/openid', 'https://graph.microsoft.com/profile']
}
const storageAccount = settings.REACT_APP_STORAGE_ACCOUNT; // "ncameo042020"
const storageKey = settings.REACT_APP_STORAGE_KEY; // "ncameo042020"

const uploadContainer = settings.REACT_APP_UPLOAD_CONTAINER;
const uploadStorageAccount = settings.REACT_APP_UPD_STORAGE_ACCOUNT;
const uploadStorageKey = settings.REACT_APP_UPD_STORAGE_KEY;
const metadataContainer = settings.REACT_APP_METADATA_CONTAINER ? settings.REACT_APP_METADATA_CONTAINER : 'ncameo';

// Msal Configurations
const config = {
    auth: {
        authority: authority(),
        clientId: settings.REACT_APP_CLIENT_ID, // clientId(),
        redirectUri: window.location.origin + '/login/',
        validateAuthority: false,
        navigateToLoginRequestUrl: false,
        knownAuthorities: [settings.REACT_APP_B2C_BASE_URL]
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

// Msal Configurations
const configForgotPassword = {
    authority: 'https://' + settings.REACT_APP_B2C_BASE_URL + '/' + settings.REACT_APP_TENANT_URL + '/' + settings.REACT_APP_B2C_PASSWORD_RESET,
    scopes: scopes()
};

// Authentication Parameters
const authenticationParameters = {
    scopes: scopes()
}

// export const azureProvider = settings.LOGIN_SERVICE == 'azure' ? new MsalAuthProvider(config, authenticationParameters, options) : null;
export const azureProvider = {
    authenticationParameters: authenticationParameters,
    config: config,
    configForgotPassword: configForgotPassword,
    storageAccount: storageAccount,
    storageKey: storageKey,
    uploadContainer: uploadContainer,
    metadataContainer: metadataContainer,
    uploadStorageAccount: uploadStorageAccount,
    uploadStorageKey: uploadStorageKey,
    AuthTokenUrl: settings.REACT_AZURE_AUTH_URL + "/api/GetAuthToken",
    AccessTokenUrl: settings.REACT_AZURE_AUTH_URL + "/api/GetAccessToken",
    azureAuthURL: "https://login.microsoftonline.com/" + settings.REACT_APP_TENANT_URL + "/oauth2/authorize?client_id=" + settings.REACT_APP_CLIENT_ID + "&response_type=code&redirect_uri=" + window.location.origin + "&scope=openid email offline_access https://graph.microsoft.com/User.Read https://graph.microsoft.com/openid https://graph.microsoft.com/profile&state=12345&nonce=7362CAEA-9CA5-4B43-9BA3-34D7C303EBA"
}


export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages"
};