export const SET_THREAD = 'SET_THREAD';
export const SET_THREAD_TOPIC_NAME = 'SET_THREAD_TOPIC_NAME';
export const SET_THREAD_ID = 'SET_THREAD_ID';
export const setThread = (thread) => ({
    type: SET_THREAD,
    thread
});
export const setThreadTopicName = (topicName) => ({
    type: SET_THREAD_TOPIC_NAME,
    topicName
});
export const setThreadId = (threadId) => ({
    type: SET_THREAD_ID,
    threadId
});