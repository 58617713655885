export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_COOL_PERIOD = 'SET_CURRENT_USER_COOL_PERIOD';
export const SET_USERS = 'SET_USERS';
export const setChatClient = (chatClient) => ({
    type: SET_CHAT_CLIENT,
    chatClient
});
export const setContosoUser = (identity, token, displayName) => ({
    type: SET_CURRENT_USER,
    identity,
    token,
    displayName
});
export const setContosoUserCoolPeriod = (coolPeriod) => ({
    type: SET_CURRENT_USER_COOL_PERIOD,
    coolPeriod
});
export const setContosoUsers = (users) => ({
    type: SET_USERS,
    users
});