import { settings } from "../../../config/settings";
import ControlService from "../../../helper/controlservice";

export const getClaimsFromApi = async (token, applicationsclaim) => {
    var apiparam = {};
    var model = {};
    var control = ControlService().extractControl(token.menu, "claim");
    if (control.method === 'get') {
        model[token.menu.claimbindingfield] = applicationsclaim;
    } else {
        control.paramlist.forEach(option => {
            if (token.menu.claimbindingfield === option.value) {
                apiparam[token.menu.claimbindingfield] = applicationsclaim;
            } else {
                apiparam[option.key] = option.value;
            }
        });
    }
    if (control.datasource) {
        return ControlService().ServiceRequest(control.claimApi, apiparam, control, token, model)
            .then(res => {
                let result = [];
                if (control.responsefield) {
                    result = res ? res[control.responsefield] : [];
                } else {
                    result = res;
                }
                return result;
            })
            .catch(e => { return null })
    } else {
        return applicationsclaim;
    }
}
var r, g, b, hsp;
export const lightOrDark = (color) => {
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'
        )
        );
        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );
    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 130) {
        return '#000';

    }
    else {
        return '#fff';
    }
}
export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 };
}
export const gitHubBranch = async (token) => {
    var param = {};
    var control = {}
    control.method = "post";
    control.datasource = "http"
    control.headers = null
    return await ControlService().ServiceRequest(settings.REACT_APP_AZURE_FUNCTION_ENDPOINT + '/' + settings.GIT_BRANCH_API, param, control, token)
        .then(res => { return res && res.data ? res.data : res })
        .catch(e => { return null })
}
export const menu = (idx, token, application = false) => {
    // Fetch Menu
    var param = {};
    var control = {}

    if (token.loginMethod) {
        control.method = settings.METHOD.GET;
        control.model = settings.WORKBENCH.MENU;
        control.datasource = ControlService().loggedInDataSource(token, application);
    } else {
        control.method = "get";
        control.datasource = "http"
        control.headers = null
    }
    param.Id = "menu";
    param.type = "menu";
    param.idx = idx;
    return ControlService().ServiceRequest(settings.MENU_API, param, control, token)
}
export const database = (idx, token, application = false) => {
    var apiparam = {};
    var control = {}

    if (token.loginMethod) {
        control.method = settings.METHOD.LIST;
        control.model = settings.WORKBENCH.DATACONNECTIONS;
        control.datasource = ControlService().loggedInDataSource(token, application);
    } else {
        control.method = settings.METHOD.LIST;
        control.datasource = "http"
        control.headers = null

        apiparam.type = "dataconnections";
        apiparam.idx = idx;
        apiparam.pagesize = 100;
        apiparam.from = 0;
    }
    return ControlService().ServiceRequest(settings.LIST_DATASOURCE_API, apiparam, control, token);
}
export const email = (idx, token) => {
    var apiparam = {};
    var control = {}

    if (token.loginMethod) {
        control.method = settings.METHOD.LIST;
        control.model = settings.WORKBENCH.EMAILCONNECTIONS;
        control.datasource = ControlService().loggedInDataSource(token);
    } else {
        control.method = settings.METHOD.LIST;
        control.datasource = "http"
        control.headers = null

        apiparam.type = "emailconnections";
        apiparam.idx = idx;
        apiparam.pagesize = 100;
        apiparam.from = 0;
    }
    return ControlService().ServiceRequest(settings.LIST_EMAIL_API, apiparam, control, token);
}
export const filterMenubyRole = (MenuList, user) => {
    if (MenuList && MenuList['Nav']) {
        var userRolesLowerCase = Array.isArray(user.roles) ? user.roles.map(v => v.toLowerCase()) : [];
        var _menu = JSON.parse(JSON.stringify(MenuList));
        _menu.Nav = [];
        MenuList['Nav'].forEach(nav => {
            if (Array.isArray(nav.roles) && nav.roles.length > 0) {
                var res = nav.roles.filter(function (v) {
                    return userRolesLowerCase.indexOf(v.toLowerCase()) > -1;
                });
                if (Array.isArray(res) && res.length > 0) {
                    _menu.Nav.push(nav);
                }
            } else {
                _menu.Nav.push(nav);
            }
        });
        return _menu;
    } else {
        return { "Type": "Side", "Logourl": "", "Isdefault": false, "Nav": [] };
    }
}
export const isOwner = (user) => {
    var userRolesLowerCase = user && Array.isArray(user.roles) ? user.roles.map(v => v ? v.toLowerCase() : '') : [];
    return userRolesLowerCase.indexOf('developer') > -1 || userRolesLowerCase.indexOf('tenant admin') > -1
}
export const isAdministrator = (user) => {
    var userRolesLowerCase = user && Array.isArray(user.roles) ? user.roles.map(v => v ? v.toLowerCase() : '') : [];
    return userRolesLowerCase.indexOf('administrator') > -1
}
export const AuthorizedGroups = async (token, application) => {
    var url = "api/admin/getgroups";
    var apiParams = {};
    var control = {}
    if (token.loginMethod) {
        control.method = settings.METHOD.LIST;
        control.model = settings.WORKBENCH.GROUP
        control.datasource = ControlService().loggedInDataSource(token, application);
    } else {
        control.datasource = settings.DATASOURCE.HTTP;
        control.method = settings.METHOD.LIST;
        control.headers = null;
        //}
    }
    return ControlService().ServiceRequest(url, apiParams, control, token);
}
export const AuthorizedApps = async (token, application, roles) => {
    let url = "api/AppData/GetMicroapp";
    var apiparam = { roles: roles ? roles : "" };
    var control = {};

    if (token.loginMethod) {
        control.method = settings.METHOD.LIST;
        control.model = settings.WORKBENCH.APPLICATION
        control.datasource = ControlService().loggedInDataSource(token, application);
    } else {
        control.datasource = settings.DATASOURCE.HTTP;
        control.method = settings.METHOD.LIST;
        control.headers = null;
    }
    return ControlService().ServiceRequest(url, apiparam, control, token);
}