import Amplify, { Storage } from 'aws-amplify';
import { settings } from '../../../config/settings'

export function configureAmplify() {
    Amplify.configure(
        {
            Auth: {
                mandatorySignIn: true,
                region: settings.REACT_APP_AWS_REGION,
                userPoolId: settings.REACT_APP_AWS_USERPOOLID,
                userPoolWebClientId: settings.REACT_APP_AWS_USERPOOL_WEBCLIENTID,
            },
            API: {
                endpoints: [{
                    name: '',
                    endpoint: "",
                    region: ""
                }]
            },
            Storage: {
                bucket: "",
                region: "",
                identityPoolId: ""
            }
        }
    );
}
//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level) {
    Storage.configure({
        bucket: bucket ? bucket : "ncameo",
        level: level ? level : "public",
        region: "us-west-2",
        identityPoolId: "us-west-2:5b50ca84-07a2-47ea-b434-3878b22a380e"
    });
}

export const variables = {
    // uploadBucket: VARIABLES.uploadBucket
}